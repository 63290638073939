import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";

//list
export const getAsyncMetaData= createAsyncThunk("metaData/getAsyncMetaData",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action : "user_meta_data",user_id : payload.id,row_per_page : payload.row_per_page ,page_number : payload.page_number}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})


const initialState = {
    metaData : [],
    page : 1,
    count : 1,
    error : null,
    loading : false
}

const metaDataSlice = createSlice({
    name : 'metaData',
    initialState,
    reducers :{
    },
    extraReducers : {
        [getAsyncMetaData.fulfilled] : (state,action)=>{
            return {...state,
                metaData : action.payload.result.userMetaData ,
                page : action.payload.result.page,
                count : action.payload.result.count,
                loading: false ,
                error: null
            }
        },
        [getAsyncMetaData.pending] : (state,action)=>{
            return {...state,metaData : [] ,loading: true , error: null}
        },
        [getAsyncMetaData.rejected] : (state,action)=>{
            return {...state,metaData : [] ,loading: false , error: ConfigMessage.error}
        }
    }
})

export const {} = metaDataSlice.actions
export default metaDataSlice.reducer
