import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/alert/Toast";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";

//list
export const getAsyncUsers = createAsyncThunk("user/getAsyncUsers",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action : "users",row_per_page : payload.row_per_page ,page_number : payload.page_number}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})

export const getAsyncUser = createAsyncThunk("user/getAsyncUser",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action : "user",user_id : payload.id}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data.result
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})

export const deleteAsyncUser = createAsyncThunk("user/deleteAsyncUser",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.delete(Config.api + "user/delete/" + payload.id,
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        return {id : payload.id}
    }catch (error){
        return rejectWithValue([],error)
    }
})


export const postAsyncChangeStatusUser = createAsyncThunk("config/postAsyncChangeStatusUser", async (payload, {thunkAPI}) => {
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action : "change-status-user",user_id : payload.id}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        return {id : payload.id ,response : res.data}
    } catch (error) {
        return thunkAPI.rejectWithValue([], error.message)
    }
})



const initialState = {
    users : [],
    page : 1,
    count : 1,
    user : [],
    error : null,
    loading : false
}

const userSlice = createSlice({
    name : 'user',
    initialState,
    reducers :{
        increments : (state,action)=>{
            state.value += action.payload
        },
        decrements : (state,action)=>{
            state.value -= action.payload
        }
    },
    extraReducers : {
        [getAsyncUsers.fulfilled] : (state,action)=>{
            if(action.payload.status === "success"){
                return {...state,
                    users :action.payload.result.users ,
                    page : action.payload.result.page,
                    count : action.payload.result.count,
                    user : [] ,
                    loading: false ,
                    error: null}
            }else {
                return {...state,users : [],user : [] ,loading: false , error: action.payload.message}
            }
        },
        [getAsyncUsers.pending] : (state,action)=>{
            return {...state,users : [],user : [] ,loading: true , error: null}
        },
        [getAsyncUsers.rejected] : (state,action)=>{
            return {...state,users : [],user : [] ,loading: false , error: ConfigMessage.error}
        },
        [getAsyncUser.fulfilled] : (state,action)=>{
            return {...state,users : [],user : action.payload ,loading: false , error: null}
        },
        [getAsyncUser.pending] : (state,action)=>{
            return {...state,users : [],user : [] ,loading: true , error: null}
        },
        [getAsyncUser.rejected] : (state,action)=>{
            return {...state,users : [],user : [] ,loading: false , error: ConfigMessage.error}
        },
        [deleteAsyncUser.fulfilled] : (state,action)=>{
            state.users = state.users.filter((item) => item.id !== action.payload.id)
        },
        [postAsyncChangeStatusUser.fulfilled]: (state, action) => {
            Toast.success(action.payload.response.message)
            state.users = state.users.filter((item) => item.user_id === action.payload.id ? item.user_status = action.payload.response.result : item)
        },
        [postAsyncChangeStatusUser.rejected]: (state, action) => {
            Toast.error(action.payload.value.message)
        }
    }
})

export const {increments,decrements} = userSlice.actions
export default userSlice.reducer
