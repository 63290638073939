import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";

//list
export const getAsyncContacts = createAsyncThunk("contact/getAsyncContacts",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action : "contacts",row_per_page : payload.row_per_page ,page_number : payload.page_number}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})

export const getAsyncContact = createAsyncThunk("contact/getAsyncContact",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.get(Config.api + "contact/" + payload.id,
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data.result
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})

export const getAsyncContactIp = createAsyncThunk("contact/getAsyncContactIp",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.get("http://ip-api.com/json/" + payload.ip,)
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})

export const deleteAsyncDelete = createAsyncThunk("contact/deleteAsyncDelete",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.delete(Config.api + "report/delete/" + payload.id,
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        return {id : payload.id}
    }catch (error){
        return rejectWithValue([],error)
    }
})


const initialState = {
    contacts : [],
    page : 1,
    count : 1,
    contact : [],
    contactIp: {},
    error : null,
    loading : false
}

const contactSlice = createSlice({
    name : 'contact',
    initialState,
    reducers :{
        increments : (state,action)=>{
            state.value += action.payload
        },
        decrements : (state,action)=>{
            state.value -= action.payload
        }
    },
    extraReducers : {
        [getAsyncContacts.fulfilled] : (state,action)=>{
            if(action.payload.status === "success"){
                return {...state,
                    contacts :action.payload.result.contacts ,
                    page : action.payload.result.page,
                    count : action.payload.result.count,
                    contact : [],
                    contactIp : {},
                    loading: false,
                    error: null}
            }else {
                return {...state,contacts : [],contact : [], contactIp : {} ,loading: false , error: action.payload.message}
            }
        },
        [getAsyncContacts.pending] : (state,action)=>{
            return {...state,contacts : [],contact : [], contactIp : {} ,loading: true , error: null}
        },
        [getAsyncContacts.rejected] : (state,action)=>{
            return {...state,contacts : [],contact : [], contactIp : {} ,loading: false , error: ConfigMessage.error}
        },
        [getAsyncContact.fulfilled] : (state,action)=>{
            return {...state,contacts : [],contact : action.payload, contactIp : {} ,loading: false , error: null}
        },
        [getAsyncContact.pending] : (state,action)=>{
            return {...state,contacts : [],contact : [], contactIp : {} ,loading: true , error: null}
        },
        [getAsyncContact.rejected] : (state,action)=>{
            return {...state,contacts : [],contact : [], contactIp : {} ,loading: false , error: ConfigMessage.error}
        },
        [getAsyncContactIp.fulfilled] : (state,action)=>{
            return {...state,contacts : [],contact : [], contactIp : action.payload ,loading: false , error: null}
        },
        [getAsyncContactIp.pending] : (state,action)=>{
            return {...state,contacts : [],contact : [], contactIp : {} ,loading: true , error: null}
        },
        [getAsyncContactIp.rejected] : (state,action)=>{
            return {...state,contacts : [],contact : [], contactIp : {} ,loading: false , error: ConfigMessage.error}
        },
        [deleteAsyncDelete.fulfilled] : (state,action)=>{
            state.contacts = state.contacts.filter((item) => item.id !== action.payload.id)
        }
    }
})

export const {increments,decrements} = contactSlice.actions
export default contactSlice.reducer
