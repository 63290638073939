import React, {useEffect} from 'react';
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import PageTitle from "../../components/ui/PageTitle";
import Loading from "../../components/messageRedux/Loading";
import Error from "../../components/messageRedux/Error";
import {addNew, getAsyncVariable, postAsyncAdsVariable} from "../../features/variable/variableSlice";
import VariableItem from "../../components/variable/VariableItem";
import {BiLoaderAlt} from "react-icons/bi";

const Variable = () => {


    const {variable , loading,loadingAdd , error} = useSelector(state => state.variable)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getAsyncVariable())
    },[])

    let data = {
        Requests : variable
    }

    const onSubmit = (values) => {
        dispatch(postAsyncAdsVariable({values : values}))
    }

    const formik = useFormik({
        initialValues : data,
        onSubmit,
        enableReinitialize : true
    })

    const addHandler = (e) => {
        e.preventDefault()
        dispatch(addNew(formik.values.Requests))
    }

    console.log(loading)

    return (
        <div className="flex flex-col bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 gap-6 mb-32 shadow">
            <PageTitle label="متغییر ها"/>

            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && (
                <form className="flex flex-col gap-9" onSubmit={formik.handleSubmit}>
                    <table className="  ">
                        <thead>
                        <tr className="bg-gray-200 text-gray-600 dark:bg-[#111827] dark:text-gray-300 uppercase text-sm leading-normal ">
                            <th className="py-3 md:text-base sm:text-sm text-xs md:w-32 sm:w-16 w-12 md:text-center text-right md:p-0 pr-3">ردیف</th>
                            <th className="py-3 md:text-base sm:text-sm text-xs px-3 text-right">نام متغییر</th>
                            <th className="py-3 md:text-base sm:text-sm text-xs px-3s text-right">مقدار</th>
                            <th className="py-3 md:text-base sm:text-sm text-xs px-3s text-right">وضعیت</th>
                            <th className="py-3 md:text-base sm:text-sm text-xs md:w-20 text-center md:p-0 pl-3">عملیات</th>
                        </tr>
                        </thead>

                        <VariableItem formik={formik} list={data.Requests}/>

                    </table>

                    <div className="flex flex-row gap-2">
                        <button disabled={loadingAdd} type="submit" className="text-center w-36 text-white bg-indigo-500 dark:bg-indigo-600 dark:hover:bg-indigo-700
                        hover:bg-indigo-600 font-medium rounded-lg text-sm
                         px-5 py-2.5 mr-2 mb-2 disabled:bg-gray-500 flex flex-row gap-2 justify-center items-center">
                            <span> ثبت تغییرات</span>
                            {loadingAdd && (<BiLoaderAlt className="animate-spin" size={20}/>) }
                        </button>

                        <button  onClick={addHandler} className="focus:outline-none text-white bg-green-600 dark:bg-green-700 dark:hover:bg-green-800  hover:bg-green-500 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  ">
                            افزودن سطر جدید
                        </button>

                    </div>

                </form>
            )}

        </div>
    )
        ;
};

export default Variable;
