import React, {useEffect} from 'react';
import persianDate from "persian-date";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncUser} from "../../features/user/userSlice";
import PageTitle from "../ui/PageTitle";
import Loading from "../messageRedux/Loading";
import Error from "../messageRedux/Error";

const UserDetails = ({id}) => {

    const {user , loading , error} = useSelector(state => state.user)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getAsyncUser({id : id}))
    },[])

    return (
        <div className="flex flex-col">
            <PageTitle label="اطلاعات کاربر"/>
            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && user &&  user.map(item => {
                return(
                    <div className="flex flex-col space-y-3 dark:text-gray-300 text-gray-700 ">
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">آیدی دستگاه : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{item.device_id} </div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">نام دستگاه : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{item.device_name}</div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">نمایشگر دستگاه : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{item.device_display}</div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">سیستم عامل : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{item.device_os}</div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">زبان دستگاه : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{item.device_locale}</div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">ورژن برنامه : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{item.app_version_name}</div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">ورژن کد : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{item.app_version_code}</div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">پکیج برنامه : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{item.app_package}</div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">آیدی بیلد : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{item.app_build_id}</div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">وضعیت کاربر : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{item.user_status}</div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">زمان ساخت اکانت : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{new persianDate.unix(item.user_register_time).format("HH-mm-ss YYYY-MM-DD")}</div>
                        </div>
                        <div className="grid grid-cols-12 sm:text-base text-sm ">
                            <div className="sm:col-span-3 col-span-5 ">آخرین ورود : </div>
                            <div className="sm:col-span-9 col-span-7 break-words">{new persianDate.unix(item.user_last_login_time).format("HH-mm-ss YYYY-MM-DD")}</div>
                        </div>

                    </div>
                )
            }) }
        </div>
    );
};

export default UserDetails;
