import React from 'react';
import {FiTrash2} from "react-icons/fi";
import {useDispatch} from "react-redux";
import {deleteAsyncAppBlock} from "../../features/setting/appBlockSlice";
import {trueFalse} from "../../assets/data/Data";
import SelectOption from "../input/SelectOption";

const AppBlockItem = ({row = 1, list,formik}) => {
    const dispatch = useDispatch()
    return (
        <tbody className="text-gray-600 text-sm font-light">
        {list && list.map((item,index)=>{
            return (
                <tr key={item.blocked_app_id} className={`${parseInt(row) % 2 === 0 ? "bg-gray-50" : ""} border-b border-gray-200 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700`}>
                    <td className="py-2 px-1 text-xs sm:text-sm  whitespace-nowrap text-lg">
                        <input disabled={true} {...formik.getFieldProps(`Requests[${index}].blocked_app_id`)} type="text" name={`Requests[${index}].blocked_app_id`} className={` bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 dark:border-gray-600 border border-gray-300 text-gray-900 text-sm rounded-lg focus-visible:outline-0 focus-visible:ring-2 focus-visible:ring-blue-500  block w-full p-2.5`}/>
                    </td>
                    <td className="py-2 px-1 text-xs sm:text-sm whitespace-nowrap">
                        <input dir="ltr" {...formik.getFieldProps(`Requests[${index}].blocked_app_name`)} type="text" name={`Requests[${index}].blocked_app_name`} className={` bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 dark:border-gray-600 border border-gray-300 text-gray-900 text-sm rounded-lg focus-visible:outline-0 focus-visible:ring-2 focus-visible:ring-blue-500  block w-full p-2.5`}  />
                    </td>
                    <td className="py-2 px-1 text-xs sm:text-sm   whitespace-nowrap">
                        <input dir="ltr" {...formik.getFieldProps(`Requests[${index}].blocked_app_package`)} type="text" name={`Requests[${index}].blocked_app_package`} className={` bg-gray-50 dark:bg-[#1f2937] dark:text-gray-200 dark:border-gray-600 border border-gray-300 text-gray-900 text-sm rounded-lg focus-visible:outline-0 focus-visible:ring-2 focus-visible:ring-blue-500  block w-full p-2.5`}  />
                    </td>
                    <td className="py-2 px-1 text-xs sm:text-sm   whitespace-nowrap">
                        <SelectOption witLabel={false} formik={formik} name={`Requests[${index}].blocked_app_all_contain_with`} list={trueFalse} />
                    </td>
                    <td className="py-2 mx-auto  text-center whitespace-nowrap">
                        <div className="flex item-center justify-center">
                            <FiTrash2 onClick={()=>dispatch(deleteAsyncAppBlock({id : item.blocked_app_id , requests :formik.values.Requests}))} className="cursor-pointer text-red-600 dark:text-red-500 hover:bg-red-600 hover:text-gray-200 dark:hover:text-gray-800 rounded-full p-1" size={32}/>
                        </div>
                    </td>
                </tr>
            )
        })}
        </tbody>
    );
};

export default AppBlockItem;
