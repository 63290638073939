import React from 'react';
import {BiLoaderAlt} from "react-icons/bi";

const Button = ({formik, onclick = null, name, status = null}) => {
    return (
        <button disabled={!formik.isValid} onClick={onclick} type="submit"
                className="w-full text-white bg-indigo-500 hover:bg-indigo-600 font-medium rounded-lg
                disabled:bg-gray-500 disabled:cursor-auto
                text-sm px-5 py-2.5 text-center mb-2 cursor-pointer">
            <div className="flex flex-row justify-center items-center gap-2">
                {status === 'pending' && (
                    <BiLoaderAlt size={18} className="animate-spin"/>
                )}

                {name}
            </div>
        </button>
    )
}
export default Button;
