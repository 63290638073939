import React from 'react';
import Input from "../../components/input/Input";
import SelectOption from "../../components/input/SelectOption";
import {server_signal, vpn_server_protocol, vpn_server_status} from "../../assets/data/Data";
import SelectOptionComplete from "../../components/input/SelectOptionComplete";
import RadioButtonModern from "../../components/input/RadioButtonModern";
import TextArea from "../../components/input/TextArea";
import Button from "../../components/button/Button";

const ConfigForm = ({formik,regions,status,defaultValue}) => {
    return (
        <form className="" onSubmit={formik.handleSubmit}>
            <div className="grid md:grid-cols-1 grid-cols-1 gap-x-4">
                <Input formik={formik} name="comment" label="کامنت"/>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                <Input formik={formik} name="group_fa_name" label="نام فارسی گروه"/>
                <Input formik={formik} name="group_en_name" label="نام انگلیسی گروه"/>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                <Input formik={formik} name="vpn_server_fa_title" label="نام فارسی سرور"/>
                <Input formik={formik} name="vpn_server_en_title" label="نام انگلیسی سرور"/>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                <Input formik={formik} name="vpn_server_fa_sub_title" label="توضیحات بیشتر فارسی"/>
                <Input formik={formik} name="vpn_server_en_sub_title" label="توضیحات بیشتر انگلیسی"/>
            </div>
            <hr className="mt-6 mb-4 dark:border-gray-400" />
            <div className="grid md:grid-cols-3 grid-cols-1 gap-x-4">
                <Input formik={formik} name="vpn_server_ip" label="آیپی سرور"/>
                <SelectOption formik={formik} name="vpn_server_signal" label="سیگنال سرور" list={server_signal}/>
                <Input formik={formik} name="vpn_server_users_count" onlyNum={true} placeholder={1000} label="ظرفیت هر سرور"/>
                <SelectOptionComplete formik={formik} name="region_id" label="منطقه سرور" list={regions} OpId="region_id" opText="region_fa_name" opValue="region_id"/>
                <SelectOption formik={formik} name="vpn_server_status" label="وضعیت سرور" list={vpn_server_status}/>
            </div>

            <div className="flex flex-col mt-6 mb-4 bg-gray-100 p-5 rounded-lg dark:bg-gray-900 dark:shadow-none dark:border dark:border-gray-600">
                <RadioButtonModern defaultValue={defaultValue} formik={formik} name="vpn_server_protocol" label="انتخاب پروتکل" list={vpn_server_protocol}/>
                <TextArea dir="ltr" formik={formik} name="vpn_server_config" label="محتویات فایل"/>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                <Input formik={formik} name="vpn_server_config_username" label="نام کاربری کانفیگ" placeholder="اگر نام کاربری ندارید خالی بگذارید"/>
                <Input formik={formik} name="vpn_server_config_password" label="رمزعبور کانفیگ" placeholder="اگر رمزعبور ندارید خالی بگذارید"/>
            </div>

            <div className="mt-8">
                <Button formik={formik} name="ثبت" status={status}/>
            </div>
        </form>
    );
};

export default ConfigForm;
