import React, { useState} from 'react';
import UserDetails from "../../components/user/UserDetails";
import {useParams} from "react-router-dom";
import Event from "../../components/user/Event";
import MetaData from "../../components/user/MetaData";
import Connections from "../../components/user/Connections";

const User = () => {

    const {id} = useParams()

    const [showTabTitle,setSHowTabTitle] = useState("detail")
    const [showTab,setSHowTab] = useState(<UserDetails id={id}/>)

    const TabChange = (input) => {
        switch (input) {
            case "detail":
                setSHowTabTitle('detail')
                setSHowTab(<UserDetails id={id}/>)
                break
            case "metaData":
                setSHowTabTitle('metaData')
                setSHowTab(<MetaData id={id}/>)
                break
            case "event":
                setSHowTabTitle('event')
                setSHowTab(<Event id={id}/>)
                break
            case "connections":
                setSHowTabTitle('connections')
                setSHowTab(<Connections id={id}/>)
                break
        }
    }

    return (
        <div className="flex flex-col gap-y-4">
            <div className="grid grid-cols-4 text-center p-3 w-full  bg-white shadow dark:shadow-none  dark:bg-gray-700 rounded-lg gap-4">
                <button onClick={()=>TabChange("detail")} className={`${showTabTitle === "detail" ? "bg-indigo-500 text-gray-200" : "dark:bg-gray-600 bg-gray-300" } cursor-pointer flex flex-row justify-center items-center text-gray-800 dark:text-gray-200 px-4 py-2 text-center  rounded-lg`}>اطلاعات کاربر</button>
                <button onClick={()=>TabChange("metaData")} className={`${showTabTitle === "metaData" ? "bg-indigo-500 text-gray-200" : "dark:bg-gray-600 bg-gray-300" } cursor-pointer flex flex-row justify-center items-center text-gray-800 dark:text-gray-200 px-4 py-2 text-center  rounded-lg`}>رفتار کاربر</button>
                <button onClick={()=>TabChange("event")} className={`${showTabTitle === "event" ? "bg-indigo-500 text-gray-200" : "dark:bg-gray-600 bg-gray-300" } cursor-pointer flex flex-row justify-center items-center text-gray-800 dark:text-gray-200 px-4 py-2 text-center  rounded-lg`}>رویدادها</button>
                <button onClick={()=>TabChange("connections")} className={`${showTabTitle === "connections" ? "bg-indigo-500 text-gray-200" : "dark:bg-gray-600 bg-gray-300" } cursor-pointer flex flex-row justify-center items-center text-gray-800 dark:text-gray-200 px-4 py-2 text-center  rounded-lg`}>کانکشن</button>
            </div>
            <div className="bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg shadow p-6 flex flex-col gap-y-8">
                {showTab}
            </div>
        </div>
    );
};

export default User;
