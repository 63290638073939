import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAsyncContacts} from "../../features/contact/contactSlice";
import ContactsItem from "../../components/contact/ContactsItem";
import Loading from "../../components/messageRedux/Loading";
import Error from "../../components/messageRedux/Error";
import Paging from "../../components/dataTable/Paging";

const Contacts = () => {

    const {contacts ,count ,page, loading , error} = useSelector(state => state.contact)
    const dispatch = useDispatch()
    const [perPage,setPerPage] = useState(30)
    const [numberPage,setNumberPage] = useState(1)

    useEffect(()=>{
        dispatch(getAsyncContacts({row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    },[perPage,numberPage])


    return (
        <div className="flex flex-col bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 gap-6 mb-32 shadow">
            <div>
                <h1 className="text-xl dark:text-gray-300">گزارشات کاربران</h1>
            </div>
            <div>
                {!loading && error === null && contacts && (
                    <div className="flex flex-row justify-end items-center gap-2 ">
                        <label htmlFor="" className="text-gray-700 dark:text-gray-400">تعداد در صفحه</label>
                        <select onChange={(e) => setPerPage(e.target.value)} name="" id="" className="p-1 px-3 w-28 rounded-lg text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                            <option value="30">30 عدد</option>
                            <option value="50">50 عدد</option>
                            <option value="100">100 عدد</option>
                        </select>
                    </div>
                )}

                {loading && (<Loading/>)}
                {error && (<Error error={error}/>)}
                {!loading && error === null && contacts && contacts.map((item,index)=>{
                    return (
                        <ContactsItem
                            key={index}
                            id = {item.contact_id}
                            userId={item.device_id}
                            deviceName={item.device_name}
                            date={item.contact_create_time}
                            description={item.description}
                            email={item.email}
                            connection={item.connection_issue}
                            application={item.application_issue}
                            speed={item.speed_issue}
                            ads={item.ads_issue}
                            server={item.servers_issue}
                        />
                    )
                })}

                {!loading && error === null && contacts && (
                    <div className="flex justify-between items-center p-3 dark:bg-gray-600 bg-gray-200 rounded-lg mt-3" aria-label="Table navigation">
                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">درحال نمایش <span className="font-semibold text-gray-900 dark:text-white">{((parseInt(numberPage)-1) * parseInt(perPage))+1}-{parseInt(perPage) * parseInt(numberPage) < count ? parseInt(perPage) * parseInt(numberPage) : count}</span> از <span className="font-semibold text-gray-900 dark:text-white">{count}</span></span>
                        <Paging page={page} setNumberPage={setNumberPage} numberPage={numberPage}/>
                    </div>
                )}

            </div>
        </div>
    );
};

export default Contacts;
