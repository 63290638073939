import React, {useEffect} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncRegions} from "../../features/regions/regionsSlice";
import {postAsyncAdsConfig} from "../../features/config/configSlice";
import {getAsyncConnectionType} from "../../features/connectionType/connectionType";
import ConfigForm from "../../features/config/ConfigForm";

const AddConfig = () => {


    const {status} = useSelector(state => state.config)
    const {regions} = useSelector(state => state.regions)
    const {connectionType} = useSelector(state => state.connectionType)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAsyncRegions())
        dispatch(getAsyncConnectionType())
       if(status === 'success'){
           formik.resetForm()
       }
    }, [status])

    const initialValues = {
        comment: "",
        group_fa_name: "سریع",
        group_en_name: "fast",
        vpn_server_fa_title: "سریع",
        vpn_server_en_title: "fast",
        vpn_server_fa_sub_title: "دایرکت",
        vpn_server_en_sub_title: "direct",
        region_id: "1",
        vpn_server_status: "up",
        vpn_server_ip: "",
        vpn_server_signal: "a",
        vpn_server_users_count: "1000",
        vpn_server_config_username: "",
        vpn_server_config_password: "",
        vpn_server_config: "",
        vpn_server_protocol: connectionType
    }

    const validationSchema = Yup.object({
        comment: Yup.string().required("کامنت ضروری است"),
        group_fa_name: Yup.string().required("نام گروه فارسی ضروری است"),
        group_en_name: Yup.string().required("نام گروه انگیسی ضروری است"),
        vpn_server_fa_title: Yup.string().required("نام فارسی ضروری است"),
        vpn_server_en_title: Yup.string().required("نام انگلیسی ضروری است"),
        vpn_server_ip: Yup.string().required("آیپی ضروری است"),
        vpn_server_users_count: Yup.string().required("ظرفیت سرور ضروری است")
    })

    const onSubmit = (values) => {
        if (status !== 'pending') {
            dispatch(postAsyncAdsConfig({values: values}))
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit,
    })

    console.log(formik.values)


    return (
        <div className="bg-white shadow dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 flex flex-col bg-white rounded-lg p-6 gap-6 mb-32">
            <div><h2 className="font-bold text-gray-600 dark:text-gray-300">افزودن کافیگ جدید</h2></div>
            <ConfigForm status={status} formik={formik} regions={regions} defaultValue={connectionType}/>
        </div>
    );
};

export default AddConfig;
