import React, {useEffect} from 'react';
import AppBlockItem from "../../components/setting/AppBlockItem";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {addNew, getAsyncAppBlock, postAsyncAdsAppBlock} from "../../features/setting/appBlockSlice";
import PageTitle from "../../components/ui/PageTitle";
import Loading from "../../components/messageRedux/Loading";
import Error from "../../components/messageRedux/Error";

const AppBlock = () => {


    const {apps , loading , error} = useSelector(state => state.appBlock)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getAsyncAppBlock())
    },[])

    let data = {
        Requests : apps
    }

    const onSubmit = (values) => {
         dispatch(postAsyncAdsAppBlock({values : values}))
    }

    const formik = useFormik({
        initialValues : data,
        onSubmit,
        enableReinitialize : true
    })

    const addHandler = (e) => {
      e.preventDefault()
        // setGetData([...formik.values.Requests, {blocked_app_id: "جدید", blocked_app_name: '', blocked_app_package: '', blocked_app_all_contain_with: 'true'}])
        dispatch(addNew(formik.values.Requests))
    }


    return (
        <div className="flex flex-col bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 gap-6 mb-32 shadow">
            <PageTitle label="اپلیکشن های بلاک شده"/>

            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && (
            <form className="flex flex-col gap-9" onSubmit={formik.handleSubmit}>
                <table className="  ">
                    <thead>
                    <tr className="bg-gray-200 text-gray-600 dark:bg-[#111827] dark:text-gray-300 uppercase text-sm leading-normal ">
                        <th className="py-3 md:text-base sm:text-sm text-xs md:w-32 sm:w-16 w-12 md:text-center text-right md:p-0 pr-3">ردیف</th>
                        <th className="py-3 md:text-base sm:text-sm text-xs px-3 text-right">نام نرمافزار</th>
                        <th className="py-3 md:text-base sm:text-sm text-xs px-3s text-right">نام پکیج</th>
                        <th className="py-3 md:text-base sm:text-sm text-xs px-3s text-right">بلاک مشابه</th>
                        <th className="py-3 md:text-base sm:text-sm text-xs md:w-20 text-center md:p-0 pl-3">عملیات</th>
                    </tr>
                    </thead>

                    <AppBlockItem formik={formik} list={data.Requests}/>

                </table>

                <div>
                    <button type="submit" className="w-36 text-white bg-indigo-500 dark:bg-indigo-600 dark:hover:bg-indigo-700 hover:bg-indigo-600  focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none  ">
                        ثبت تغییرات
                    </button>

                    <button  onClick={addHandler} className="focus:outline-none text-white bg-green-600 dark:bg-green-700 dark:hover:bg-green-800  hover:bg-green-500 focus:ring-4 focus:ring-green-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  ">
                        افزودن سطر جدید
                    </button>

                </div>

            </form>
            )}

        </div>
    )
        ;
};

export default AppBlock;
