import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";
import {Toast} from "../../components/alert/Toast";

//list
export const getAsyncReports = createAsyncThunk("report/getAsyncReports", async (payload, {rejectWithValue}) => {
    try {
        const res = await axios.post(Config.api, JsonToWWWFormData({
                action: "logs",
                row_per_page: payload.row_per_page,
                page_number: payload.page_number
            }),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncReport = createAsyncThunk("report/getAsyncReport", async (payload, {rejectWithValue}) => {
    try {
        const res = await axios.post(Config.api, JsonToWWWFormData({action: "log", log_id: payload.id}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const deleteAsyncReport = createAsyncThunk("report/deleteAsyncReport", async (payload, {rejectWithValue}) => {
    try {
        const res = await axios.post(Config.api, JsonToWWWFormData({action: "delete-log", log_id: payload.id}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        return {id: payload.id , response : res.data}
    } catch (error) {
        return rejectWithValue([], error)
    }
})


const initialState = {
    reports: [],
    page: 1,
    count: 1,
    report: [],
    error: null,
    loading: false
}

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        increments: (state, action) => {
            state.value += action.payload
        },
        decrements: (state, action) => {
            state.value -= action.payload
        }
    },
    extraReducers: {
        [getAsyncReports.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                return {
                    ...state, reports: action.payload.result.logs,
                    page: action.payload.result.page,
                    count: action.payload.result.count,
                    report: [], loading: false, error: null
                }
            } else {
                return {...state, reports: [], report: [], loading: false, error: action.payload.message}
            }
        },
        [getAsyncReports.pending]: (state, action) => {
            return {...state, reports: [], report: [], loading: true, error: null}
        },
        [getAsyncReports.rejected]: (state, action) => {
            return {...state, reports: [], report: [], loading: false, error: ConfigMessage.error}
        },

        [getAsyncReport.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                return {...state, report: action.payload.result, loading: false, error: null}
            } else {
                return {...state, report: [], loading: false, error: action.payload.message}
            }
        },
        [getAsyncReport.pending]: (state, action) => {
            return {...state, report: [], loading: true, error: null}
        },
        [getAsyncReport.rejected]: (state, action) => {
            return {...state, report: [], loading: false, error: ConfigMessage.error}
        },

        [deleteAsyncReport.fulfilled]: (state, action) => {
            if(action.payload.response.status === "success"){
                state.reports = state.reports.filter((item) => item.id !== action.payload.id)
                Toast.success(action.payload.response.message)
            }else{
                Toast.error(action.payload.response.message)
            }

        },
        [deleteAsyncReport.rejected]: (state, action) => {
            Toast.error(action.payload.response.message)
        },
    }
})

export const {increments, decrements} = reportSlice.actions
export default reportSlice.reducer
