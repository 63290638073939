import logoBlue from "../assets/images/logo/logo2.png";
import logoIndexFooter from "../assets/images/logo/logo2.png";
import avatar from "../assets/images/avatar/avatar.png"
const hostname = document.location.hostname

export const Config = {
    setTime : "aefa16c5-f953-498a-8daf-d9e95e74cc35",
    version : "2.1.0",
    team : "dev7",
    site : "dev7.dev",
    api : `https://${hostname}/interfaces/site/`,
    // api : `http://localhost/dvpn-api/interfaces/site/`,
    // apiGetIpDetails : "https://ip2geoapp.com/api/geoip?ipaddress=",
    // apiGetIpDetails : "http://ip-api.com/json/",
    apiGetIpDetails : "https://freeipapi.com/api/json/",
    logo : logoBlue,
    authorName : "",
    panelTitle : "Dev7 Panel",
    loginTitle : "سامانه مدیریت",
    logoIndexFooter : logoIndexFooter,
    supportSocial : "",
    avatar : avatar,
    telegramChannel  : "",
    telegramSupport  : "",

}

export const ConfigMessage = {
    error : "عملیات با خطا مواجه شد"
}

export const RowsPerPage = {
    report  : 10,
    user  : 10,
}

export const RegExp = {
    phone :  /^(0|\+98)?([ ]|-|[()]){0,2}9[1|2|3|4]([ ]|-|[()]){0,2}(?:[0-9]([ ]|-|[()]){0,2}){8}$/
}


