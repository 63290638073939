import React, {useEffect, useState} from 'react';
import persianDate from "persian-date";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncConnection} from "../../features/connections/connectionsSlice";
import {connectStatus} from "../../utility/connectStatus";
import PageTitle from "../ui/PageTitle";
import Loading from "../messageRedux/Loading";
import Error from "../messageRedux/Error";
import DataTable from "../dataTable/DataTable";

const Connections = ({id}) => {

    const {connection ,count,page, loading , error} = useSelector(state => state.connections)
    const dispatch = useDispatch()
    const [perPage,setPerPage] = useState(30)
    const [numberPage,setNumberPage] = useState(1)

    useEffect(()=>{
        dispatch(getAsyncConnection({id: id,row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    },[perPage,numberPage])


    const column = [
        {
            name: "سرور آیپی",
            selector: row => row.server_ip,
        },
        {
            name: "نام سرور",
            selector: row => row.server_name,
        },
        {
            name: "تاخیر در اتصال",
            selector: row => row.connection_duration,
        },
        {
            name: "وضعیت اتصال",
            selector: row => row.connection_state,
        },
        {
            name: "وضعیت موفقیت",
            selector: row => connectStatus[row.is_success],
        },
        {
            name: "اینترنت",
            selector: row => row.internet_info,
        },
        {
            name: "تاریخ آخرین بازدید",
            selector: row => new persianDate.unix(row.connection_create_time).format("HH-mm-ss YYYY-MM-DD"),
        }

    ];

    return (
        <div className="flex flex-col">
            <PageTitle label="کانکشن"/>
            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && (
                <DataTable
                    columns={column}
                    data={connection}
                    count={count}
                    page={page}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    numberPage={numberPage}
                    setNumberPage={setNumberPage}
                />            ) }
        </div>
    );
};

export default Connections;

