import './App.css';
import React from "react";
import Dashboard from "./pages/dashboard/Dashboard";
import {Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import ProtectedRoutes from "./components/auth/ProtectedRoutes";
import PublicRoutes from "./components/auth/PublicRoutes";
import Login from "./pages/authentication/Login";
import ChangePassword from "./pages/profile/ChangePassword";
import Profile from "./pages/profile/Profile";
import Config from "./pages/config/Config";
import AddConfig from "./pages/config/AddConfig";
import Ads from "./pages/ads/Ads";
import AdsMenu from "./pages/setting/adsMenu";
import Version from "./pages/setting/Version";
import AppBlock from "./pages/setting/AppBlock";
import Counter from "./pages/Counter";
import Reports from "./pages/report/Reports";
import Report from "./pages/report/Report";
import IpDetails from "./pages/ip/IpDetails";
import Users from "./pages/user/Users";
import User from "./pages/user/User";
import Connection from "./pages/setting/Connection";
import Contacts from "./pages/contact/Contacts";
import EditConfig from "./pages/config/EditConfig";
import About from "./pages/about/About";
import Variable from "./pages/variable/Variable";



function App() {


    return (
        <Routes>

            <Route exact  path="/login" element={<Login />} />
            <Route exact  path="" element={<Login />} />

            <Route path="/" element={<ProtectedRoutes/>}>
                <Route path="*" element={<Home/>}>
                    <Route path="dashboard" element={<Dashboard/>}/>
                    <Route path="report" element={<Reports/>}/>
                    <Route path="report/:id" element={<Report/>}/>


                    <Route path="profile" element={<Profile/>}/>
                    <Route path="profile/change-password" element={<ChangePassword/>}/>
                    <Route path="config" element={<Config/>}/>
                    <Route path="config/add" element={<AddConfig/>}/>
                    <Route path="config/edit/:id" element={<EditConfig/>}/>
                    <Route path="ads" element={<Ads/>}/>
                    <Route path="ip-details/:ip" element={<IpDetails/>}/>
                    <Route path="set-application/ads-setting" element={<AdsMenu/>}/>
                    <Route path="set-application/app-block" element={<AppBlock/>}/>
                    <Route path="set-application/version" element={<Version/>}/>
                    <Route path="set-application/connection" element={<Connection/>}/>
                    <Route path="user" element={<Users/>}/>
                    <Route path="variable" element={<Variable/>}/>
                    <Route path="user/:id" element={<User/>}/>
                    <Route path="contact" element={<Contacts/>}/>
                    <Route path="counter" element={<Counter/>}/>
                    <Route path="about" element={<About/>}/>
                </Route>
            </Route>

            <Route path="login" element={<PublicRoutes/>}>
                <Route path="/login" element={<Login/>}/>
            </Route>

        </Routes>
    );
}

export default App;
