import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";

//list
export const getAsyncRegions = createAsyncThunk("regions/getAsyncRegions",async (_,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action: "regions"}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data.result
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})

const initialState = {
    regions : [],
    error : null,
    loading : false
}

const regionsSlice = createSlice({
    name : 'regions',
    initialState,
    reducers :{
        increments : (state,action)=>{
            state.value += action.payload
        },
        decrements : (state,action)=>{
            state.value -= action.payload
        }
    },
    extraReducers : {
        [getAsyncRegions.fulfilled] : (state,action)=>{
            return {...state,regions : action.payload ,loading: false , error: null}
        },
        [getAsyncRegions.pending] : (state,action)=>{
            return {...state,regions : [] ,loading: true , error: null}
        },
        [getAsyncRegions.rejected] : (state,action)=>{
            return {...state,regions : [] ,loading: false , error: ConfigMessage.error}
        }
    }
})

export const {increments,decrements} = regionsSlice.actions
export default regionsSlice.reducer
