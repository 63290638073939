import React,{useEffect, useState} from 'react';
import Sidebar from "../components/layout/sidebar/Sidebar";
import Header from "../components/layout/header/Header";
import {Outlet} from "react-router-dom";
import ToastContainer from "../components/alert/ToastContainer";
import {useSelector} from "react-redux";

const Home = () => {



    const show = " w-64 transition-full duration-500 ease-in-out response  z-40 absolute bg-white shadow h-full flex-col overflow-hidden "
    const normal = "w-0 xl:w-full transition-full xl:transition-none duration-500 ease-in-out absolute xl:relative z-40 md:z-0 bg-white shadow h-full flex-col overflow-hidden xl:block xl:col-span-2 xl:row-span-2"

    const [isMobile, setIsMobile] = useState(false);
    const [mobileClass, setMobileClass] = useState(normal);
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const [profileMenuClose, setProfileMenuClose] = useState(true);


    const sidebarHandler = () => {
        document.getElementById("sidebar").classList.contains('response') ? setMobileClass(normal) : setMobileClass(show)
        document.getElementById("sidebar").classList.contains('response') ? setIsMobile(false) : setIsMobile(true)
    }

    const mobileHandler = () => window.innerWidth >= 1280 ? setIsMobile(false) : setIsMobile(false)
    const removeProfileMenu = () => {
        setProfileMenuOpen(false)
        setProfileMenuClose(true)
    }

    useEffect(() => {
        window.addEventListener('resize', mobileHandler)

        if(profileMenuClose === false){
            window.addEventListener("click", removeProfileMenu);

        }

        return () =>{
            window.removeEventListener('resize', mobileHandler)
            window.removeEventListener('click', removeProfileMenu)
        }
    });

    const profileMenuHandler = () => {
        setProfileMenuOpen(!profileMenuOpen)
        setTimeout(() => {
            setProfileMenuClose(!profileMenuClose)
        }, 500)
    }

    const {dark} = useSelector(state => state.theme)


    return (
        <div className="w-full bg-[#F8F8F8]">
            <div className="relative grid grid-cols-12 grid-rows-[85px_minmax(900px,_1fr)] dark:bg-[#1f2937]">
                <Sidebar sidebarHandler={sidebarHandler} mobileClass={mobileClass} isMobile={isMobile}/>
                <Header sidebarHandler={sidebarHandler} profileMenuHandler={profileMenuHandler} profileMenuOpen={profileMenuOpen}/>
                <div className="col-span-12 xl:col-span-10 mx-4 mt-4 xl:mx-8 xl:mt-8 ">
                    <Outlet/>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
};

export default Home;
