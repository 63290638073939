import React, {useEffect} from 'react';
import Input from "../../components/input/Input";
import Button from "../../components/button/Button";
import {useFormik} from 'formik'
import {Config} from "../../config/Config";
import * as Yup from 'yup'
import {Licence, LoginUser, setTime} from "../../getData/login/Login";
import ToastContainer from "../../components/alert/ToastContainer";
import {Toast} from "../../components/alert/Toast";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";


const Login = () => {


    const navigate = useNavigate()

    const {dark} = useSelector(state => state.theme)

    const initialValues = {
        username : "",
        password : ""
    }

    const validationSchema = Yup.object({
        username: Yup.string().required("نام کاربری ضروری است"),
        password: Yup.string().required("رمز عبور ضروری است")
    })

    const onSubmit = (values) => {

        LoginUser(values).then((res)=>{
            if(res.status === "success"){

                setTime().then((response)=>{
                    if(response.status === "success"){
                        localStorage.setItem("licence" ,JSON.stringify(response.license))
                        localStorage.setItem("product" ,JSON.stringify(response.product))
                        localStorage.setItem("developerInfo" ,JSON.stringify(response.developer_info))

                        if(response.product.product_force_update === "disable"){

                            localStorage.setItem("token" ,res.result.token)
                            Toast.success(res.message)
                            setTimeout(()=>{
                                return navigate('/dashboard')
                            },1000)

                        }else {
                            Toast.error('این نسخه نیازمند بروز رسانی است لطفا با پشتیبانی خود تماس بگیرید.',10000)
                        }
                    }else {
                        Toast.error(response.message,10000)
                    }
                })

            }else {
                Toast.error(res.message)
            }
        })





    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit,
    })

    useEffect(()=>{
        const token = localStorage.getItem('token');
        if(token !== undefined && token !== null && token.length > 20){
            return navigate("/dashboard")
        }
    })

    return (
        <div className="container h-screen flex mx-auto justify-center items-center">
            <div className="sm:w-96 w-80  rounded-xl bg-white shadow-md overflow-hidden dark:bg-[#111827] dark:shadow-none">
                <form className="p-6" onSubmit={formik.handleSubmit}>
                    <img className="sm:w-44 w-36 mx-auto" src={Config.logo} alt="سامانه مدیریت"/>
                    <h1 className="sm:text-2xl text-xl my-6 text-center dark:text-gray-300">{Config.loginTitle}</h1>
                    <Input formik={formik} label='نام کاربری' name='username'/>
                    <Input formik={formik} label='رمز عبور' name='password'/>
                    <div className="mt-8">
                        <Button formik={formik} name="ورود"/>
                    </div>
                </form>
                <div className="bg-gray-300 dark:bg-gray-700 text-center p-1 hover:bg-gray-200">
                    <a dir="ltr"  className="font-bold dark:text-gray-400 text-gray-700" href="https://jdjjd.com">{Config.version}</a>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
};

export default Login;
