import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/alert/Toast";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";
import formurlencoded from "form-urlencoded";

//list
export const getAsyncConfigs = createAsyncThunk("config/getAsyncConfigs", async (payload, {rejectWithValue}) => {
    try {
        const res = await axios.post(Config.api, JsonToWWWFormData({
                action: "config",
                protocol: payload.protocol,
                row_per_page: payload.row_per_page,
                page_number: payload.page_number
            }),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const getAsyncConfig = createAsyncThunk("config/getAsyncConfig", async (payload, {rejectWithValue}) => {
    try {
        const res = await axios.post(Config.api, JsonToWWWFormData({action: "get-config", vpn_server_id: payload.id}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data.result
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const postAsyncAdsConfig = createAsyncThunk("config/postAsyncAdsConfig", async (payload, {thunkAPI}) => {
    try {
        const res = await axios.post(Config.api, formurlencoded({...payload.values, action: "add-config"}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    } catch (error) {
        return thunkAPI.rejectWithValue([], error.message)
    }
})

export const editAsyncAdsConfig = createAsyncThunk("config/editAsyncAdsConfig", async (payload, {thunkAPI}) => {
    try {
        const res = await axios.post(Config.api, formurlencoded({...payload.values, action: "edit-config"}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    } catch (error) {
        return thunkAPI.rejectWithValue([], error.message)
    }
})


export const deleteAsyncConfig = createAsyncThunk("config/deleteAsyncConfig", async (payload, {rejectWithValue}) => {
    try {
        const res = await axios.post(Config.api, formurlencoded({action: "delete_config", vpn_server_id: payload.id}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        return {id: payload.id, message: res.data.message}
    } catch (error) {
        return rejectWithValue([], error)
    }
})

export const getAsyncDefaultProtocol = createAsyncThunk("config/getAsyncDefaultProtocol", async (payload, {rejectWithValue}) => {
    try {
        const res = await axios.post(Config.api, formurlencoded({action: "default-protocol"}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    } catch (error) {
        return rejectWithValue([], error)
    }
})

const initialState = {
    configs: [],
    defaultProtocol: 'openvpn',
    page: 1,
    count: 1,
    config: {},
    error: null,
    loading: false,
    status: "idle"
}

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        clearConfig: (state, action) => {
            return {...state, configs: [], config: {}, loading: false, error: null}
        },
        decrements: (state, action) => {
            state.value -= action.payload
        }
    },
    extraReducers: {
        [getAsyncConfigs.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                return {
                    ...state,
                    configs: action.payload.result.config,
                    page: action.payload.result.page,
                    count: action.payload.result.count,
                    config: {},
                    loading: false,
                    error: null
                }
            } else {
                return {...state, configs: [], config: {}, loading: false, error: action.payload.message}
            }
        },
        [getAsyncConfigs.pending]: (state, action) => {
            return {...state, configs: [], config: {}, loading: true, error: null}
        },
        [getAsyncConfigs.rejected]: (state, action) => {
            return {...state, configs: [], config: {}, loading: false, error: ConfigMessage.error}
        },

        [getAsyncConfig.fulfilled]: (state, action) => {
            Toast.clear()
            return {...state, configs: [], config: action.payload, loading: false, error: null}
        },
        [getAsyncConfig.pending]: (state, action) => {
            Toast.info('درحال دریافت اطلاعات')
            return {...state, configs: [], config: {}, loading: true, error: null}
        },
        [getAsyncConfig.rejected]: (state, action) => {
            return {...state, configs: [], config: {}, loading: false, error: ConfigMessage.error}
        },
        [deleteAsyncConfig.rejected]: (state, action) => {
            Toast.success(action.payload.message)
        },
        [deleteAsyncConfig.fulfilled]: (state, action) => {
            Toast.success(action.payload.message)
            state.configs = state.configs.filter((item) => item.vpn_server_id !== action.payload.id)
        },
        [postAsyncAdsConfig.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.status = 'success'
                Toast.success(action.payload.message)
            } else {
                state.status = 'error'
                Toast.error(action.payload.message)
            }
        },
        [postAsyncAdsConfig.pending]: (state, action) => {
            state.status = 'pending'
        },
        [postAsyncAdsConfig.rejected]: (state, action) => {
            state.status = 'error'
            Toast.error(action.payload.message)
        },

        [editAsyncAdsConfig.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.status = 'success'
                Toast.success(action.payload.message)
            } else {
                state.status = 'error'
                Toast.error(action.payload.message)
            }
        },
        [editAsyncAdsConfig.pending]: (state, action) => {
            state.status = 'pending'
        },
        [editAsyncAdsConfig.rejected]: (state, action) => {
            state.status = 'error'
            Toast.error(action.payload.message)
        },


        [getAsyncDefaultProtocol.fulfilled]: (state, action) => {
            return {...state, defaultProtocol: action.payload.result,}
        },

    }
})

export const {clearConfig, decrements} = configSlice.actions
export default configSlice.reducer
