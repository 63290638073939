import React from 'react';
import {Navigate, Outlet, useNavigate} from "react-router-dom";

const useAuth = () => {
    const token = localStorage.getItem('token');
    return (token !== undefined && token !== null && token.length > 20)

}

const ProtectedRoutes = (props) => {
    const auth = useAuth();
    return auth ? <Outlet /> : <Navigate to="/login" />
    return auth ? "" : localStorage.clear()
};

export default ProtectedRoutes;
