import React, {useEffect} from 'react';
import SelectOption from "../../components/input/SelectOption";
import { trueFalse} from "../../assets/data/Data";
import {useFormik} from "formik";
import Input from "../../components/input/Input";
import TextArea from "../../components/input/TextArea";
import Button from "../../components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncVersion, postAsyncVersion} from "../../features/setting/versionSlice";
import PageTitle from "../../components/ui/PageTitle";
import RadioButtonModernStatusTwo from "../../components/input/RadioButtonModernStatusTwo";
import Loading from "../../components/messageRedux/Loading";
import Error from "../../components/messageRedux/Error";


const Version = () => {

    const {version,error,loading,status} = useSelector(state => state.version)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getAsyncVersion())
    },[])


    const onSubmit = (values) => {
        if(status !== 'pending'){
            dispatch(postAsyncVersion({values : values}))
        }
    }

    const formik = useFormik({
        initialValues : version,
         validateOnMount: true,
        enableReinitialize : true,
        onSubmit,
    })

    return (
        <div className="flex flex-col bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 gap-6 mb-32 shadow">
            <PageTitle label="نسخه و آپدیت"/>
            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && (
                <form className="flex flex-col gap-9" onSubmit={formik.handleSubmit}>
                    <div className="mb-6">
                        <h2 className="font-bold text-gray-600 dark:text-gray-300">کشورهای بلاک شده :</h2>
                    </div>
                    <RadioButtonModernStatusTwo formik={formik} name="BLOCKED_COUNTRIES_ENABLED" label="وضعیت" enable="true" disable="false" />
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                        <Input formik={formik} name="BLOCKED_COUNTRIES_LIST" label="گشور های بلاک"/>
                        <Input formik={formik} name="BLOCKED_COUNTRIES_BUTTON_TITLE" label="عنوان دکمه"/>
                        <Input formik={formik} name="BLOCKED_COUNTRIES_BUTTON_EVENT_URL" label="لینک دکمه"/>
                        <Input formik={formik} name="BLOCKED_COUNTRIES_TITLE" label="عنوان نمایش"/>
                        <TextArea formik={formik} name="BLOCKED_COUNTRIES_DETAILS" label="توضیحات نمایش"/>
                    </div>
                    <hr className="dark:border-gray-400" />

                    <div>
                        <h2 className="font-bold text-gray-600 dark:text-gray-300">لودینگ :</h2>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                        <Input formik={formik} name="APP_LOAD_TIMEOUT" label="زمان لود برنامه"/>
                    </div>

                    <hr className="dark:border-gray-400" />
                    <div className="flex flex-col gap-y-4">
                        <div className="mb-6">
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">تنظیمات آپدیت :</h2>
                        </div>
                        <RadioButtonModernStatusTwo formik={formik} name="UPDATE_SHOW_STATUS" label="نمایش آپدیت" />
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <Input formik={formik} name="LATEST_VERSION" label="نسخه فعلی"/>
                            <SelectOption formik={formik} name="FORCE_UPDATE_ENABLED" label="آپدیت اجباری" list={trueFalse}/>
                            <Input formik={formik} name="UPDATE_TITLE" label="موضوع بروز رسانی"/>
                            <TextArea formik={formik} name="UPDATE_DETAILS" label="جزئیات بروزرسانی"/>
                            <Input formik={formik} name="UPDATE_BUTTON_EVENT_URL" label="لینک بروزرسانی"/>
                            <Input formik={formik} name="UPDATE_SIZE" label="حجم بروزرسانی جدید (مگابایت)"/>
                        </div>
                    </div>

                    <div className="mt-8">
                        <Button formik={formik} name="ثبت" status={status} />
                    </div>
                </form>
            )}

        </div>
    );
};

export default Version;
