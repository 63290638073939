import React from 'react';
import {formatNumber} from "../../utility/formatNumber";
import {BiLoaderAlt} from "react-icons/bi";
import {MdOutlineDangerous} from "react-icons/md";

const ChartBlockShow = ({label,icon,value,status}) => {

    return (
        <div className="flex flex-col bg-white dark:bg-gray-700 dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 gap-6  shadow">
            <div className="dark:text-gray-300 text-gray-700 flex flex-row justify-between items-center p-4 gap-8 ">
                <div className="flex flex-col justify-center items-center gap-2">
                    {icon}
                    <span>{label}</span>
                </div>
                <span className="font-bold text-4xl ">
                    {status === 'success' ? formatNumber(value) : status !== 'error' ? (
                        (
                            <div className="animate-spin">
                                <BiLoaderAlt size={50}/>
                            </div>
                        )
                    ) : (
                        (
                            <div className="">
                                <MdOutlineDangerous size={50}/>
                            </div>
                        )
                    )}

                </span>
            </div>
        </div>
    );
};

export default ChartBlockShow;
