import React from 'react';
import Input from "../../input/Input";
import SelectOption from "../../input/SelectOption";
import {threeStatus, trueFalse} from "../../../assets/data/Data";


const AdsItem = ({list,formik}) => {
    return (
        <>
            {list && list.map((item,index) => {
                return (
                        <div key={item.ad_id}>
                            <hr className="md:hidden dark:border-gray-300 block mt-5 "/>
                            <div key={index} className="grid grid-cols-12 gap-x-3">
                                <div className="md:col-span-4 col-span-12 ">
                                    <Input formik={formik} name={`Requests[${index}].ad_token_value`} label={item.ad_token_name} />
                                </div>
                                <div className="md:col-span-2 col-span-6">
                                    <Input formik={formik} name={`Requests[${index}].ad_timeout`} label="تایم اوت" />
                                </div>
                                <div className="md:col-span-2 col-span-6">
                                    <Input formik={formik} name={`Requests[${index}].ad_try_to_show`} label="دفعات تلاش" />
                                </div>
                                <div className="md:col-span-2 col-span-6">
                                    <SelectOption list={trueFalse} formik={formik}  name={`Requests[${index}].ad_show_in_limited_country`} label="نمایش کشور محدود" />
                                </div>
                                <div className="md:col-span-2 col-span-6">
                                    <SelectOption list={threeStatus} formik={formik}  name={`Requests[${index}].ad_show_status`} label="وضعیت نمایش" />
                                </div>
                            </div>
                        </div>
                )
            })}

        </>
    );
};

export default AdsItem;
