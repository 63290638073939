import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/alert/Toast";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";

//list
export const getAsyncAdsMenu = createAsyncThunk("adsMenu/getAsyncAdsMenu",async (_,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action : "dialog-details"}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})


export const postAsyncAdsMenu = createAsyncThunk("adsMenu/postAsyncAdsMenu", async (payload, {thunkAPI}) => {
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({...payload.values ,action : "add-dialog-details"}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    } catch (error) {
        return thunkAPI.rejectWithValue([], error.message)
    }
})


const initialState = {
    adsMenu : [],
    error : null,
    loading : false,
    status : "idle"
}

const adsMenuSlice = createSlice({
    name : 'adsMenu',
    initialState,
    reducers :{

    },
    extraReducers : {
        [getAsyncAdsMenu.fulfilled] : (state,action)=>{
            if(action.payload.status === "success"){
                return {...state,adsMenu :action.payload.result ,loading: false , error: null}
            }else {
                return {...state,adsMenu : [] ,loading: false , error: action.payload.message}
            }
        },
        [getAsyncAdsMenu.pending] : (state,action)=>{
            return {...state,adsMenu : [],loading: true , error: null}
        },
        [getAsyncAdsMenu.rejected] : (state,action)=>{
            return {...state,adsMenu : [],loading: false , error: ConfigMessage.error}
        },

        [postAsyncAdsMenu.fulfilled]: (state, action) => {
            if(action.payload.status === "success"){
                state.status = 'success'
                Toast.success(action.payload.message)
            }else {
                state.status = 'error'
                Toast.error(action.payload.message)
            }
        },
        [postAsyncAdsMenu.pending] : (state,action)=>{
            state.status = 'pending'
        },
        [postAsyncAdsMenu.rejected]: (state, action) => {
            state.status = 'error'
            Toast.error("عملیات با مشکل روبرو شده")
        }
    }
})

export const {} = adsMenuSlice.actions
export default adsMenuSlice.reducer
