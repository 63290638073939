import React, {useEffect, useState} from 'react';
import persianDate from "persian-date";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncEvent} from "../../features/event/eventSlice";
import PageTitle from "../ui/PageTitle";
import Loading from "../messageRedux/Loading";
import Error from "../messageRedux/Error";
import DataTable from "../dataTable/DataTable";

const Event = ({id}) => {

    const {event ,count,page, loading , error} = useSelector(state => state.event)
    const dispatch = useDispatch()
    const [perPage,setPerPage] = useState(30)
    const [numberPage,setNumberPage] = useState(1)

    useEffect(()=>{
        dispatch(getAsyncEvent({id: id,row_per_page: parseInt(perPage), page_number: parseInt(numberPage)}))
    },[perPage,numberPage])


    const column = [
        {
            name: "نام رویداد",
            selector: row => row.event_name,
        },
        {
            name: "توضیح رویداد",
            selector: row => row.event_description,
        },
        {
            name: "تاریخ آخرین بازدید",
            selector: row => new persianDate.unix(row.event_create_time).format("HH-mm-ss YYYY-MM-DD"),
        }

    ];

    return (
        <div className="flex flex-col">
            <PageTitle label="رویدادها"/>
            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && (
                <DataTable
                    columns={column}
                    data={event}
                    count={count}
                    page={page}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    numberPage={numberPage}
                    setNumberPage={setNumberPage}
                />
            ) }
        </div>
    );
};

export default Event;

