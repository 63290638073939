import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'

const initialState = {
    dark : true
}

const themeSlice = createSlice({
    name : 'theme',
    initialState,
    reducers :{
        changeThemeRedux : (state,action)=>{
            state.dark = action.payload
        }
    }
})

export const {changeThemeRedux} = themeSlice.actions
export default themeSlice.reducer
