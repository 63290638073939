import React, {useEffect} from 'react';
 import {useFormik} from "formik";
import TextArea from "../../components/input/TextArea";
import Input from "../../components/input/Input";
import RadioButtonModernStatus from "../../components/input/RadioButtonModernStatus";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncAdsMenu, postAsyncAdsMenu} from "../../features/setting/adsMenuSlice";
import PageTitle from "../../components/ui/PageTitle";
import Button from "../../components/button/Button";
import RadioButtonModernStatusTwo from "../../components/input/RadioButtonModernStatusTwo";
import Loading from "../../components/messageRedux/Loading";
import Error from "../../components/messageRedux/Error";

const AdsMenu = () => {

    const {adsMenu,error,loading,status} = useSelector(state => state.adsMenu)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getAsyncAdsMenu())
    },[])

    const onSubmit = (values) => {
        if(status !== 'pending'){
            dispatch(postAsyncAdsMenu({values : values}))
        }
    }

    const formik = useFormik({
        initialValues : adsMenu,
        enableReinitialize : true,
        onSubmit,
    })



    return (
        <div className="flex flex-col bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 gap-6 mb-32 shadow">
            <PageTitle label="دیالوگ"/>
            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && (
                <form className="flex flex-col gap-y-6" onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col gap-y-4">
                        <div className="mb-10">
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">تبلیغات بنری :</h2>
                        </div>
                        <RadioButtonModernStatus  formik={formik} label="وضعیت"  name="CUSTOM_BANNER_SHOW_STATUS"/>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <Input formik={formik} name="CUSTOM_BANNER_IMAGE_URL" label="آدرس عکس"/>
                            <Input formik={formik} name="CUSTOM_BANNER_EVENT_URL" label="لینک"/>
                        </div>
                    </div>
                    <hr className="dark:border-gray-400" />
                    <div className="flex flex-col gap-y-4">
                        <div className="mb-10">
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">تبلیغات دیالوگ :</h2>
                        </div>
                        <RadioButtonModernStatusTwo  formik={formik} label="وضعیت"  name="CUSTOM_DIALOG_SHOW_STATUS" disable="disable" enable="enable"/>
                        <RadioButtonModernStatusTwo   formik={formik} label="نمایش"  name="CUSTOM_DIALOG_SHOW_TYPE"
                                                      disable="random" enable="always" disableName="هوشمند" enableName="همیشه"/>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <Input formik={formik} name="CUSTOM_DIALOG_BUTTON_TITLE" label="عنوان دکمه"/>
                            <Input formik={formik} name="CUSTOM_DIALOG_BUTTON_EVENT_URL" label="لینک دکمه"/>
                            <Input formik={formik} name="CUSTOM_DIALOG_TITLE" label="عنوان"/>
                            <TextArea formik={formik} name="CUSTOM_DIALOG_DETAILS" label="توضیحات"/>
                        </div>
                    </div>
                    <hr className="dark:border-gray-400" />
                    <div className="flex flex-col gap-y-4">
                        <div className="mb-10">
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">گوگل review :</h2>
                        </div>
                        <RadioButtonModernStatus  formik={formik} label="وضعیت"  name="GOOGLE_REVIEW_SHOW_STATUS"/>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <Input formik={formik} name="GOOGLE_REVIEW_SHOW_STEPS" label="تعداد دفعات نمایش"/>
                        </div>
                    </div>
                    <hr className="dark:border-gray-400" />
                    <div className="flex flex-col gap-y-4">
                        <div className="mb-10">
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">پرایویسی :</h2>
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <Input formik={formik} name="PRIVACY_POLICIES_URL" label="آدرس (privacy policy)"/>
                        </div>
                    </div>
                    <hr className="dark:border-gray-400" />
                    <div className="flex flex-col gap-y-4">
                        <div className="mb-10">
                            <h2 className="font-bold text-gray-600 dark:text-gray-300">درباره ما :</h2>
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4">
                            <Input formik={formik} name="TELEGRAM_CHANNEL" label="کانال تلگرام"/>
                            <Input formik={formik} name="TELEGRAM_SUPPORT" label="پشتیبانی تلگرام"/>
                            <Input formik={formik} name="WEBSITE_URL" label="آدرس سایت"/>
                        </div>
                    </div>


                    <div className="mt-8">
                        <Button formik={formik} name="ثبت" status={status}/>
                    </div>

                </form>
            )}

        </div>
    );
};

export default AdsMenu;
