import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";

//list
export const getAsyncAdsSetting = createAsyncThunk("adsSetting/getAsyncAdsSetting",async (_,{rejectWithValue})=>{
    try {
        const res = await axios.get(Config.api + "ads-setting",
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})



const initialState = {
    adsSetting : {},
    errorAdsSetting : null,
    loadingAdsSetting : false
}

const adsSettingSlice = createSlice({
    name : 'adsSetting',
    initialState,
    reducers :{
        increments : (state,action)=>{
            state.value += action.payload
        }
    },
    extraReducers : {
        [getAsyncAdsSetting.fulfilled] : (state,action)=>{
            if(action.payload.status === "success"){
                return {...state,adsSetting :action.payload.result  ,loadingAdsSetting: false , errorAdsSetting: null}
            }else {
                return {...state,adsSetting : []  ,loadingAdsSetting: false , errorAdsSetting: action.payload.message}
            }
        },
        [getAsyncAdsSetting.pending] : (state,action)=>{
            return {...state,adsSetting : {},loadingAdsSetting: true , errorAdsSetting: null}
        },
        [getAsyncAdsSetting.rejected] : (state,action)=>{
            return {...state,adsSetting : {},loadingAdsSetting: false , errorAdsSetting: ConfigMessage.error}
        }
    }
})

export const {increments} = adsSettingSlice.actions
export default adsSettingSlice.reducer
