import React, {useEffect, useState} from 'react';
import {FaPowerOff, FaRegClock} from "react-icons/fa";
import {TbSettingsAutomation} from "react-icons/tb";

const RadioButtonModernStatus = ({formik,name,label}) => {
    const [status, setStatus] = useState("غیرفعال");
    return (
       <div className="flex flex-row items-center gap-4">
           <label className="text-base dark:text-gray-300">
               {label} :
           </label>
           <div className={`grid grid-cols-3 bg-gray-200 rounded-full px-1.5 py-1 dark:bg-gray-700`}>
               <div key='radio1'>
                   <input key="1" {...formik.getFieldProps(name)} type="radio"  name={name} id={'disable' + name} value="disable" defaultChecked={formik.values[name] === 'disable' ? true : null} className="peer hidden"/>
                   <label onClick={()=>setStatus('غیرفعال')} htmlFor={'disable' + name} className="dark:text-white transition-all text-xs md:text-base block cursor-pointer select-none rounded-full p-2 text-center peer-checked:bg-gray-500 peer-checked:font-bold peer-checked:text-white">
                       <FaPowerOff className="rotate-180" size={22}/>
                   </label>
               </div>
               <div key='radio2'>
                   <input key="2" {...formik.getFieldProps(name)} type="radio"  name={name} id={'enable' + name} value="enable" defaultChecked={formik.values[name] === 'enable' ? true : null} className="peer hidden"/>
                   <label onClick={()=>setStatus('فعال')} htmlFor={'enable' + name} className="dark:text-white transition-all text-xs md:text-base block cursor-pointer select-none rounded-full p-2 text-center peer-checked:bg-green-600 peer-checked:font-bold peer-checked:text-white">
                       <FaPowerOff size={22}/>
                   </label>
               </div>
               <div key='radio3'>
                   <input key="3" {...formik.getFieldProps(name)} type="radio"  name={name} id={'random' + name} value="random" defaultChecked={formik.values[name] === 'random' ? true : null} className="peer hidden"/>
                   <label onClick={()=>setStatus('هوشمند')} htmlFor={'random' + name} className="dark:text-white transition-all text-xs md:text-base block cursor-pointer select-none rounded-full p-2 text-center peer-checked:bg-indigo-500 peer-checked:font-bold peer-checked:text-white">
                       {/* eslint-disable-next-line react/jsx-no-undef */}
                       <FaPowerOff className="-rotate-90" size={22}/>
                   </label>
               </div>
           </div>
           <div className="text-sm text-gray-600 dark:text-gray-400">{formik.values[name] === 'disable' ? "غیر فعال" : formik.values[name] === 'enable' ? "فعال" : "هوشمند"}</div>
       </div>
    );
};

export default RadioButtonModernStatus;
