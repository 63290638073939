import {createSlice} from '@reduxjs/toolkit'




const counterSlice = createSlice({
    name : 'counter',
    initialState : {value : 0},
    reducers :{
        increments : (state,action)=>{
            state.value += action.payload
        },
        decrements : (state,action)=>{
            state.value -= action.payload
        }
    }
})

export const {increments,decrements} = counterSlice.actions
export default counterSlice.reducer
