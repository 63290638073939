import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";

// export const getAsyncIp = createAsyncThunk("ip/getAsyncIp",async (payload,{rejectWithValue})=>{
//     try {
//         const res = await axios.get(Config.apiGetIpDetails + payload.ip,
//             {
//                 headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
//             })
//         const value = res.data
//         return await value;
//     }catch (error){
//         return rejectWithValue([],error.message)
//     }
// })

export const getAsyncIp = createAsyncThunk("ip/getAsyncIp",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.get(Config.apiGetIpDetails + payload.ip,
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})

const initialState = {
    ipDetails: [],
    error : null,
    loading : false
}

const ipSlice = createSlice({
    name : 'ip',
    initialState,
    reducers :{
        increments : (state,action)=>{
            state.value += action.payload
        },
        decrements : (state,action)=>{
            state.value -= action.payload
        }
    },
    extraReducers : {
        [getAsyncIp.fulfilled] : (state,action)=>{
            return {...state, ipDetails : action.payload ,loading: false , error: null}
        },
        [getAsyncIp.pending] : (state,action)=>{
            return {...state, ipDetails : [] ,loading: true , error: null}
        },
        [getAsyncIp.rejected] : (state,action)=>{
            return {...state, ipDetails :[] ,loading: false , error: ConfigMessage.error}
        }
    }
})

export const {increments,decrements} = ipSlice.actions
export default ipSlice.reducer
