
export const Toast = {
    success (desc, time = 3000) {
        Alert(desc, 'bg-green-600 dark:bg-green-800', time)
    },
    warning (desc, time = 3000) {
        Alert(desc, 'bg-yellow-600 dark:bg-yellow-800', time)
    },
    error (desc, time = 3000) {
        Alert(desc, 'bg-red-600 dark:bg-red-800', time)
    },
    info (desc, time = 3000) {
        Alert(desc, 'bg-blue-600 dark:bg-blue-800', time)
    },
    clear () {
        document.getElementById('alertShow').innerHTML = "";
    }
}


const Alert = (desc, type, time) => {

    setTimeout(function () {
        document.getElementById('alertShow').innerHTML = "";
    }, time);

    document.getElementById('alertShow').innerHTML =
        `<div class="${type} rounded-lg" role="alert">
             <div class="text-white flex flex-row justify-center item-center px-7 py-3">${desc}</div>
        </div>`;
}
