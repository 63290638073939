import React, {useState} from 'react';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from "react-icons/md";
import SelectOption from "../input/SelectOption";
import {select_protocol} from "../../assets/data/Data";
import Paging from "./Paging";

const DataTable = ({columns, data, count, page,perPage, setPerPage,numberPage,setNumberPage}) => {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-end items-center gap-2 ">
                <label htmlFor="" className="text-gray-700 dark:text-gray-400">تعداد در صفحه</label>
                <select onChange={(e) => setPerPage(e.target.value)} name="" id="" className="p-1 px-3 w-28 rounded-lg text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                    <option value="30">30 عدد</option>
                    <option value="50">50 عدد</option>
                    <option value="100">100 عدد</option>
                </select>
            </div>

            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        {columns && columns.length > 0 && columns.map((item, index) => {
                            return (
                                <th scope="col" className="py-3 px-6">
                                    {item.name}
                                </th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {data && data.length > 0 && data.map((row, index) => {
                        return (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {columns && columns.length > 0 && columns.map((itemColumns) => {
                                    return (
                                        <td className="py-4 px-6">
                                            {[row].map(itemColumns.selector)}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}

                    </tbody>
                </table>
                <nav className="flex justify-between items-center p-6" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">درحال نمایش <span className="font-semibold text-gray-900 dark:text-white">{((parseInt(numberPage)-1) * parseInt(perPage))+1}-{parseInt(perPage) * parseInt(numberPage) < count ? parseInt(perPage) * parseInt(numberPage) : count}</span> از <span className="font-semibold text-gray-900 dark:text-white">{count}</span></span>
                    <Paging page={page} setNumberPage={setNumberPage} numberPage={numberPage}/>
                </nav>
            </div>
        </div>

    );
};

export default DataTable;





