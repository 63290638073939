import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/alert/Toast";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";
import formurlencoded from "form-urlencoded";

//list
export const getAsyncAppBlock = createAsyncThunk("appBlock/getAsyncAppBlock",async (_,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api,JsonToWWWFormData({action : "app-block"}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    }catch (error){
        return rejectWithValue([],error.message)
    }
})


export const deleteAsyncAppBlock = createAsyncThunk("appBlock/deleteAsyncAppBlock",async (payload,{rejectWithValue})=>{
    try {
        const res = await axios.post(Config.api ,JsonToWWWFormData({blocked_app_id:payload.id , action : "delete-app-block"}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        return {id : payload.id , res : res.data}
    }catch (error){
        return rejectWithValue([],error)
    }
})

export const postAsyncAdsAppBlock = createAsyncThunk("setting/postAsyncAdsAppBlock", async (payload, {thunkAPI}) => {
    try {
        const res = await axios.post(Config.api,formurlencoded({...payload.values , action: "add-app-block"}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    } catch (error) {
        return thunkAPI.rejectWithValue([], error.message)
    }
})


const initialState = {
    apps : [],
    error : null,
    loading : false
}

const appBlockSlice = createSlice({
    name : 'appBlock',
    initialState,
    reducers :{
        addNew : (state,action)=>{
            state.apps = [...action.payload, {blocked_app_id: action.payload.length + 1, blocked_app_name: '', blocked_app_package: '', blocked_app_all_contain_with: 'true'}]
        }
    },
    extraReducers : {
        [getAsyncAppBlock.fulfilled] : (state,action)=>{
            if(action.payload.status === "success"){
                return {...state,apps :action.payload.result, config : [] ,loading: false , error: null}
            }else {
                return {...state,apps : [], config : [] ,loading: false , error: action.payload.message}
            }
        },
        [getAsyncAppBlock.pending] : (state,action)=>{
            return {...state,apps : [],config : [] ,loading: true , error: null}
        },
        [getAsyncAppBlock.rejected] : (state,action)=>{
            return {...state,apps : [],config : [] ,loading: false , error: ConfigMessage.error}
        },
        [deleteAsyncAppBlock.fulfilled] : (state,action)=>{
            // Toast.success("سطر با موفقیت حذف شد")
            // state.apps = action.payload.requests.filter((item) => item.blocked_app_id !== action.payload.id)

            if(action.payload.res.status === "success"){
                Toast.success(action.payload.res.message)
                state.apps = state.apps.filter((item) => item.blocked_app_id !== action.payload.id)
            }else {
                Toast.error(action.payload.res.message)
            }

        },
        [postAsyncAdsAppBlock.fulfilled]: (state, action) => {
            if(action.payload.status === "success"){
                Toast.success(action.payload.message)
            }else {
                Toast.error(action.payload.message)
            }
        },
        [postAsyncAdsAppBlock.rejected]: (state, action) => {
            Toast.error("عملیات با مشکل روبرو شده")
        }
    }
})

export const {addNew} = appBlockSlice.actions
export default appBlockSlice.reducer
