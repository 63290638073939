import React from 'react';

const PageTitle = ({label}) => {
    return (
        <div className="mb-7">
            <h2 className="font-bold text-lg text-gray-600 dark:text-gray-300 ">
                <span>{label}</span>
            </h2>
        </div>
    );
};

export default PageTitle;
