import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import persianDate from "persian-date";
import {useDispatch, useSelector} from "react-redux";
import {getAsyncReport} from "../../features/report/reportSlice";
import Loading from "../../components/messageRedux/Loading";
import Error from "../../components/messageRedux/Error";
import DataTable from "../../components/dataTable/DataTable";


const ReportDetail = (row) => {

    const {id} = useParams()

    const {report , loading , error} = useSelector(state => state.report)
    const dispatch = useDispatch()

    useEffect(()=>{
        console.log(typeof(report))
        dispatch(getAsyncReport({id : id}))
    },[])

    return (
        <div className="flex flex-col bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 gap-8 mb-32 shadow">
            <div>
                <h1 className="text-xl dark:text-gray-300 text-gray-700">جزئیات گزارش</h1>
            </div>
            {loading && (<Loading/>)}
            {error && (<Error error={error}/>)}
            {!loading && error === null && report && (
                    <div className="flex flex-col space-y-3 dark:text-gray-300 text-gray-700 ">
                        <div className="grid grid-cols-5 text-base">
                            <div className="col-span-1">آیپی : </div>
                            <div className="col-span-4">{report.ip}
                                <span>
                                    <Link to={"/ip-details/" + report.ip} className="mr-10 bg-indigo-600 dark:bg-indigo-500 dark:hover:bg-indigo-500 hover:bg-indigo-400  rounded-lg px-2 py-2 text-sm">
                                        دریافت اطلاعات IP
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="grid grid-cols-5 text-base">
                            <div className="col-span-1">جزئیات : </div>
                            <div className="col-span-4">{report.log_details}</div>
                        </div>
                        <div className="grid grid-cols-5 text-base">
                            <div className="col-span-1">لاگ از : </div>
                            <div className="col-span-4">{report.log_from}</div>
                        </div>
                        <div className="grid grid-cols-5 text-base">
                            <div className="col-span-1">زمان لاگ : </div>
                            <div className="col-span-4">{new persianDate.unix(report.log_time).format("HH-mm-ss YYYY-MM-DD")}</div>
                        </div>
                        <div className="grid grid-cols-5 text-base">
                            <div className="col-span-1">نوع لاگ : </div>
                            <div className="col-span-4">{report.log_type}</div>
                        </div>
                        <div className="grid grid-cols-5 text-base">
                            <div className="col-span-1">سیستم عامل</div>
                            <div className="col-span-4">{report.user_agent}</div>
                        </div>
                    </div>
                )
             }

        </div>
    );
};

export default ReportDetail;
