import React,{useState} from 'react';
import {NavLink} from "react-router-dom";


const SidebarItemWhitoutSub = ({id,title,icon,link}) => {
    const [open, setOpen] = useState("0")
    const OpenMenuHandler = (id) => {
        open === '0' ? setOpen(id) : id === open ? setOpen('0') : setOpen(id)

    }

    const classActive = 'cursor-pointer z-40 w-full cursor-pointer flex flex-row justify-between items-center pr-3 py-3 pl-1 rounded-lg rounded-lg bg-indigo-500 text-white '
    const classInactive = 'cursor-pointer z-40 w-full cursor-pointer flex flex-row justify-between items-center pr-3 py-3 pl-1 rounded-lg rounded-lg  text-gray-600 dark:text-gray-300'

    return (
        <div className=" overflow-hidden">
            <NavLink  key={id} to={link} id={id} onClick={()=>OpenMenuHandler(id)} className={(navData) => (navData.isActive ? classActive : classInactive)}>
                <div className="inline-flex justify-center items-center ">
                    {icon}
                     <span className="mr-2 ">{title}</span>
                </div>
            </NavLink>
        </div>
    );
};

export default SidebarItemWhitoutSub;
