import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";
import {Config, ConfigMessage} from "../../config/Config";
import {Toast} from "../../components/alert/Toast";
import {JsonToWWWFormData} from "../../utility/JsonToWWWFormData";
import formurlencoded from 'form-urlencoded';

//list
export const getAsyncAdsProperties = createAsyncThunk("adsProperties/getAsyncAdsProperties", async (_, {rejectWithValue}) => {
    try {
        const res = await axios.post(Config.api, JsonToWWWFormData({action: "ads-properties"}),
            {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        const value = res.data
        return await value;
    } catch (error) {
        return rejectWithValue([], error.message)
    }
})

export const postAsyncAdsProperties = createAsyncThunk("adsProperties/postAsyncAdsProperties", async (payload, {thunkAPI}) => {
    try {
        const res = await axios.post(Config.api,formurlencoded({...payload.values , action: "add-ads-properties"}),
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
        const value = res.data
        return await value;
    } catch (error) {
        return thunkAPI.rejectWithValue([], error.message)
    }
})

const initialState = {
    adsSetting: {},
    adsProperties: [],
    message: null,
    error: null,
    loading: false,
    status : "idle"
}

const adsPropertiesSlice = createSlice({
    name: 'adsProperties',
    initialState,
    reducers: {
        setMessageConfig: (state, action) => {
            return {message: action.payload}
        },
        clearMessageConfig: (state, action) => {
            return {message: ""}
        }
    },
    extraReducers: {
        [getAsyncAdsProperties.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                return {
                    ...state,
                    adsSetting: action.payload.result.adsSetting,
                    adsProperties: action.payload.result.adsProperties,
                    message: null,
                    loading: false,
                    error: null
                }
            } else {
                return {
                    ...state,
                    adsSetting: {},
                    adsProperties: [],
                    message: null,
                    loading: false,
                    error: action.payload.message
                }
            }
        },
        [getAsyncAdsProperties.pending]: (state, action) => {
            return {...state, adsSetting: {}, adsProperties: [], message: null, loading: true, error: null}
        },
        [getAsyncAdsProperties.rejected]: (state, action) => {
            return {
                ...state,
                adsSetting: {},
                adsProperties: [],
                message: null,
                loading: false,
                error: ConfigMessage.error
            }
        },
        [postAsyncAdsProperties.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.status = 'success'
                Toast.success(action.payload.message)
            } else {
                state.status = 'error'
                Toast.error(action.payload.message)
            }

        },
        [postAsyncAdsProperties.pending]: (state, action) => {
            state.status = 'pending'
        },
        [postAsyncAdsProperties.rejected]: (state, action) => {
            state.status = 'error'
            Toast.error("عملیات با مشکل روبرو شده")
        }
    }
})

export const {setMessageConfig, clearMessageConfig} = adsPropertiesSlice.actions
export default adsPropertiesSlice.reducer
