import React, {useEffect, useState} from 'react';
import PageTitle from "../../components/ui/PageTitle";
import {Config} from "../../config/Config";

const About = () => {

    const[dataAbout,setDataAbout] = useState({})
    const[developerInfo,setDeveloperInfo] = useState({})

    useEffect(()=>{
        const product = JSON.parse(localStorage.getItem('product'));
        setDataAbout(product !== undefined && product !== null ? product : {})
        const developerInfo = JSON.parse(localStorage.getItem('developerInfo'))
        setDeveloperInfo(developerInfo !== undefined && developerInfo !== null ? developerInfo : {})
    })


    return (
        <div className="flex flex-col gap-4">
            <div className="bg-white shadow dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 flex-col flex gap-y-6">
                <PageTitle label="اطلاعات برنامه"/>
                <div className="dark:text-gray-300 text-gray-700 flex flex-col gap-3">
                    <div className="flex flex-row gap-3 items-center">
                        <span>نسخه درحال استفاده : </span>
                        <span dir="ltr">{Config.version}</span>

                        { dataAbout.product_version !== Config.version ? (
                            <span className="text-sm text-red-500">(نسخه {dataAbout.product_version} ارائه شده است.)</span>
                            ) : (
                            <span className="text-sm text-green-500">(شما در حال استفاده از آخرین نسخه برنامه هستید)</span>
                        )}

                    </div>
                    <div className="flex flex-row gap-3 items-center">
                        <span>نام برنامه : </span>
                        <span dir="ltr" className="fontEn">{dataAbout.product_name}</span>
                    </div>
                    <div className="flex flex-row gap-3 items-center">
                        <span>درباره برنامه : </span>
                        <span dir="ltr" className="">{dataAbout.product_info}</span>
                    </div>
                    <div className="flex flex-row gap-3 items-center">
                        <span>تیم توسعه : </span>
                        <span dir="ltr" className="fontEn">{Config.team}</span>
                    </div>
                    <div className="flex flex-row gap-3 items-center">
                        <span>پشتیبان تلگرام : </span>
                        <span dir="ltr" className="fontEn">{developerInfo.telegram_support}</span>
                    </div>
                    <div className="flex flex-row gap-3 items-center">
                        <span>کانال تلگرام : </span>
                        <span dir="ltr" className="fontEn">{developerInfo.telegram_channel}</span>
                    </div>


                    {dataAbout.product_version !== Config.version && (
                        <div className="mt-7">
                            <h2>پیام از سرور پشتیبانی : </h2>
                            <div className="border border-gray-500 dark:border-gray-200 p-4 mt-2 flex flex-col gap-4 dark:bg-gray-700 bg-gray-300 rounded">
                                <h3>
                                    <span>عنوان : </span>
                                    <span>{dataAbout.product_update_title}</span>
                                </h3>
                                <div>
                                    <span>نسخه ارائه شده : </span>
                                    <span>{dataAbout.product_version}</span>
                                </div>
                                <p><span>توضیحات : </span>{dataAbout.product_update_description}</p>

                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default About;
