import React, {useEffect} from 'react';
import Input from "../../components/input/Input";
import {useFormik} from "formik";
import AdsItem from "../../components/ads/admob/AdsItem";
import Button from "../../components/button/Button";
import PageTitle from "../../components/ui/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import {clearMessageConfig, getAsyncAdsProperties, postAsyncAdsProperties} from "../../features/ads/adsProperties";
import {getAsyncAdsSetting} from "../../features/ads/adsSetting";
import RadioButtonModernStatus from "../../components/input/RadioButtonModernStatus";
import Loading from "../../components/messageRedux/Loading";
import Error from "../../components/messageRedux/Error";

const Ads = () => {


    const {adsSetting,adsProperties, loading, error,status} = useSelector(state => state.adsProperties)
    const dispatch = useDispatch()

    const onSubmit = (values) => {
        dispatch(postAsyncAdsProperties({values: values}))

    }

    useEffect(() => {
        dispatch(getAsyncAdsProperties())
    }, [dispatch])

    let data = {
        ADS_SERVICE_SHOW_STATUS: adsSetting.ADS_SERVICE_SHOW_STATUS,
        ADS_SERVICE_LIMITED_COUNTRIES:adsSetting.ADS_SERVICE_LIMITED_COUNTRIES,
        Requests:adsProperties
    }


    const formik = useFormik({
        initialValues: data,
        onSubmit,
        validateOnMount: true,
        enableReinitialize: true

    })


    return (
        <div className="flex flex-col bg-white dark:bg-[#1f2937] dark:shadow-none dark:border dark:border-gray-600 rounded-lg p-6 gap-6 mb-32 shadow">
            <PageTitle label="سرویس های تبلیغاتی"/>
            <form onSubmit={formik.handleSubmit}>
                {loading && (<Loading/>)}
                {error && (<Error error={error}/>)}
                {!loading && error === null && (
                    <div>
                        <div>
                            <RadioButtonModernStatus formik={formik} label="نمایش تبلیغات" name="ADS_SERVICE_SHOW_STATUS"/>
                            <div className="grid md:grid-cols-1 grid-cols-1 gap-x-4 mt-3">
                                <Input dir="ltr" formik={formik} name="ADS_SERVICE_LIMITED_COUNTRIES" label="کشور های محدود شده"/>
                            </div>
                        </div>
                        <hr className="md:block dark:border-gray-300 hidden mt-10 mb-10"/>
                        <div className="flex flex-col gap-y-3">
                            <AdsItem formik={formik} list={data.Requests}/>
                        </div>
                    </div>
                )}
                <div className="mt-8">
                    <Button formik={formik} status={status} name="ثبت تغییرات"/>
                </div>
            </form>
        </div>
    );
};

export default Ads;
