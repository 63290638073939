import React, {useEffect, useState} from 'react';
import { FiMenu} from "react-icons/fi";
import {Config} from "../../../config/Config";
import {FiMoon, FiSun} from "react-icons/fi";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeThemeRedux} from "../../../features/theme/themeSlice";
import {BiLogOut} from "react-icons/bi";

const Header = ({sidebarHandler,profileMenuHandler,profileMenuOpen}) => {

    const navigate = useNavigate()
    const {dark} = useSelector(state => state.theme)
    const dispatch = useDispatch()

    const [openDropDown, setOpenDropDown] = useState(false);
    const [iconThemeMode,setIconThemeMode] = useState( <FiSun className="text-gray-500 dark:text-gray-300 dark:hover:text-indigo-500 hover:text-indigo-500" size={22}/>)

    const changeColorMode = () => {
        const htmlTag = document.getElementById('htmlTag')
        const theme = htmlTag.classList.contains('dark')
       changeTheme(theme)
    }

    const changeTheme = (theme) => {
        const htmlTag = document.getElementById('htmlTag')
        if(theme){
            dispatch(changeThemeRedux(false))
            htmlTag.classList.remove('dark')
            localStorage.setItem('theme','light')
            setIconThemeMode( <FiMoon className="text-gray-500 dark:text-gray-300 dark:hover:text-indigo-500 hover:text-indigo-500" size={22}/>)
        }else {
            dispatch(changeThemeRedux(true))
            htmlTag.classList.add('dark')
            localStorage.setItem('theme','dark')
            setIconThemeMode( <FiSun className="text-gray-500 dark:text-gray-300 dark:hover:text-indigo-500 hover:text-indigo-500" size={22}/>)
        }
    }

    const logout = () => {
        localStorage.clear()
        return navigate("/login")
    }


    useEffect(()=>{
        if(localStorage.getItem("theme") === null){
            changeTheme(false)
        }else if(localStorage.getItem('theme') === 'dark'){
            changeTheme(false)
        }else {
            changeTheme(true)
        }

    },[])

    return (
        <div className="sticky top-0 col-span-12 xl:col-span-10 flex flex-row items-end mx-4 xl:mx-8  bg-[#F8F8F8] dark:bg-[#1f2937]  z-20">
            <div className=" flex flex-row justify-between items-center shadow bg-white dark:bg-[#111827]  w-full h-16 rounded-lg py-3 px-5  ">

                <ul className="flex inline-flex  items-center justify-center gap-x-3 ">
                    <li className="xl:hidden"><span onClick={sidebarHandler}>
                                <FiMenu size={22} className="text-gray-500 dark:text-gray-300 dark:hover:text-indigo-500 hover:text-indigo-500"/></span>
                    </li>
                </ul>
                <div className="flex flex-row justify-center items-center gap-3">
                    <div onClick={changeColorMode} >
                        {iconThemeMode}
                    </div>
                    <div className="flex flex-row justify-center items-center m-2 gap-2">
                        <div>
                            <img className="w-9 h-9 rounded-full ring-2 ring-offset-2 ring-violet-500" src={Config.avatar} alt=""/>
                        </div>
                        <div className="flex flex-col ml-2 space-y-1">
                            <span className="text-sm text-gray-500 dark:text-gray-200 font-bold">مدیر</span>
                        </div>

                        <div className='flex flex-col border-r pr-2 border-gray-500 '>
                            <div onClick={() => setOpenDropDown(!openDropDown)} className="flex flex-1 justify-between items-center cursor-pointer dark:text-gray-300 text-gray-600">
                                خروج
                            </div>
                            <div className={` transition-full duration-500 ease-in-out overflow-hidden  ${openDropDown ? "max-h-32" : "max-h-0"}`}>
                                <BiLogOut onClick={() => {logout()}} size={26} className="dark:text-red-600 text-red-500 hover:text-red-400 hover:dark:text-red-400 cursor-pointer"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
