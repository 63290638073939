import React from 'react';

const SelectOption = ({formik, name, label, dir = 'rtl',list,opText,opValue,OpId}) => {

    return (
        <div className="mt-3">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
            <select {...formik.getFieldProps(name)}  id={name} name={name} className={`bg-gray-50 dark:bg-[#1f2937] dark:text-white dark:border-gray-600 dark:text-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus-visible:outline-0 focus-visible:ring-2 focus-visible:ring-indigo-500  block w-full p-2`}  required>
                {list && list.length > 0 && list.map((item)=>{
                    return (
                        <option key={item[OpId]} value={item[opValue]}>{item[opText]}</option>
                    )
                })}
            </select>
            {formik.errors[name] && formik.touched[name] && (
                <p className="mt-2 text-pink-600 text-xs">
                    {formik.errors[name]}
                </p>
            )}
        </div>
    );
};

export default SelectOption;
